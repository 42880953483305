<template>
  <div>
    <h1 class="heading">Notifications</h1>
    <div class="rooms__header" style="margin-bottom: 15px;">
      <div class="filter__item" style="width:100%;padding: 0; display: flex;flex-direction: column">
        <div style="display: flex;">
          <v-autocomplete
              style="padding:0;max-width:300px; margin-right:15px;"
              placeholder="Объект"
              outlined
              dense
              :items="$store.state.objects.list"
              item-text="name"
              v-model="$store.state.objects.item"
              @change="changeHotel"
              return-object
          >
            <template v-slot:selection="{ item }">
              <div>{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
            <template v-slot:item="{ item }">
              <div>{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
          </v-autocomplete>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end">
            <v-btn outlined small color="primary" v-if="status" :disabled="!selectedUsers.length" @click="showMessageModal">Send message</v-btn>
        </div>
      </div>
    </div>
    <div>
      <div v-if="status">
        <v-data-table
            show-select
            v-model="selectedUsers"
            class="table rooms__table"
            :headers="headers"
            :items="users"
            hide-default-footer
            item-key="id"
            :items-per-page="999"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
            <tr v-for="user in users" :key="user.id">
              <td>
                <v-checkbox v-model="selectedUsers" :value="user" style="margin:0px;padding:0px"
                            hide-details />
              </td>
              <td>{{user.id}}</td>
              <td>{{user.client_name?user.client_name:'-'}}</td>
              <td>{{user.client_email?user.client_email:'-'}}</td>
              <td class="d-block d-sm-table-cell" style="cursor: pointer">
                <div style="display: flex; align-items: center">
                  <img
                      :src="`https://guest.mobi/3x2/${changeFormatLangPic(
                          user.lang
                        )}.svg`"
                      style="width: 25px; height: 25px; margin-right: 10px"
                      :alt="user.lang"
                  />
                  <div v-html="changeFormat(user.lang)"></div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" style="text-align: center;">
                  You don't have available users
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        To see available users and send notification messages, first you need to <router-link :to="`/constructor/${$store.state.objects.item.id}?notifications=true`">activate notifications.</router-link>
      </div>
    </div>
    <v-dialog v-model="messageModal" max-width="700" eager @click:outside="closeMessageModal">
      <v-card>
        <v-btn class="popup__close" small icon @click="closeMessageModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Create new message
        </v-card-title>
        <v-card-text>
          <div class="mb-5">
            <label class="label">Image</label>
            <vue-dropzone
                ref="addMessageImage"
                id="addMessageImage"
                :options="dropzoneOptions"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="removeMessageImage"
                maxFiles="1"
                @vdropzone-success="successMessageImage"
                @vdropzone-max-files-exceeded="maxFileSexceededMessageImage"
            >
            </vue-dropzone>
          </div>
              <div class="card_body">
                <div class="card_body_item" style="display:flex;align-items: center">
                  <label class="label" style="display:block;margin-bottom: 0;width:75px;">Title</label>
                  <v-text-field dense hide-details outlined v-model="message.title">

                  </v-text-field>
                </div>
                <div class="card_body_item" style="display: flex;align-items: center">
                  <label class="label" style="display:block;margin-bottom: 0;width:75px;">Body</label>
                  <v-text-field outlined dense hide-details v-model.number="message.message">
                  </v-text-field>
                </div>
                <div class="card_body_item" style="display: flex;align-items: center">
                  <label class="label" style="display:block;margin-bottom: 0;width:75px;">Link</label>
                  <v-text-field placeholder="Link" outlined dense hide-details v-model.number="message.url">
                  </v-text-field>
                </div>
              </div>
        </v-card-text>
        <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
          <v-btn @click="sendMessage" outlined small color="primary">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PopupSuccess ref="PopupSuccess" :title="successMessage"/>
  </div>
</template>

<script>
  import store from "@/store"
  import NotificationService from "../services/notification.service";
  import titleMixin from "../mixins/titleMixin";

  import vueDropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import PopupSuccess from "@/components/Popup/PopupSuccess"
  export default {
    mixins:[titleMixin],
    components:{
      vueDropzone,
      PopupSuccess
    },
    title(){
      return "Notifications"
    },
    name: "Notifications",
    beforeRouteEnter (to, from, next) {
      if(!store.state.objects.list.length){
        store.dispatch('objects/getList')
          .then((data) => {
            next();
          }).catch(() => {
          next();
        })
      }else{
        next();
      }
    },
    data(){
      return{
        message:{
          image:null,
          title:"default title",
          message:"default body",
          url:""
        },
        successMessage:"",
        dropzoneOptions: {
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          destroyDropzone: true,
          maxFiles: 1,
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-file";
          },
        },
        status:false,
        selectedUsers:[],
        users:[],
        countries: [
          { value: "AF", label: "Afghanistan" },
          { value: "AX", label: "Åland Islands" },
          { value: "AL", label: "Albania" },
          { value: "DZ", label: "Algeria" },
          { value: "AS", label: "American Samoa" },
          { value: "AD", label: "Andorra" },
          { value: "AO", label: "Angola" },
          { value: "AI", label: "Anguilla" },
          { value: "AQ", label: "Antarctica" },
          { value: "AG", label: "Antigua and Barbuda" },
          { value: "AR", label: "Argentina" },
          { value: "AM", label: "Armenia" },
          { value: "AW", label: "Aruba" },
          { value: "AU", label: "Australia" },
          { value: "AT", label: "Austria" },
          { value: "AZ", label: "Azerbaijan" },
          { value: "BS", label: "Bahamas" },
          { value: "BH", label: "Bahrain" },
          { value: "BD", label: "Bangladesh" },
          { value: "BB", label: "Barbados" },
          { value: "BY", label: "Belarus" },
          { value: "BE", label: "Belgium" },
          { value: "BZ", label: "Belize" },
          { value: "BJ", label: "Benin" },
          { value: "BM", label: "Bermuda" },
          { value: "BT", label: "Bhutan" },
          { value: "BO", label: "Bolivia, Plurinational State of" },
          { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
          { value: "BA", label: "Bosnia and Herzegovina" },
          { value: "BW", label: "Botswana" },
          { value: "BV", label: "Bouvet Island" },
          { value: "BR", label: "Brazil" },
          { value: "IO", label: "British Indian Ocean Territory" },
          { value: "BN", label: "Brunei Darussalam" },
          { value: "BG", label: "Bulgaria" },
          { value: "BF", label: "Burkina Faso" },
          { value: "BI", label: "Burundi" },
          { value: "KH", label: "Cambodia" },
          { value: "CM", label: "Cameroon" },
          { value: "CA", label: "Canada" },
          { value: "CV", label: "Cape Verde" },
          { value: "KY", label: "Cayman Islands" },
          { value: "CF", label: "Central African Republic" },
          { value: "TD", label: "Chad" },
          { value: "CL", label: "Chile" },
          { value: "CN", label: "China" },
          { value: "CX", label: "Christmas Island" },
          { value: "CC", label: "Cocos (Keeling) Islands" },
          { value: "CO", label: "Colombia" },
          { value: "KM", label: "Comoros" },
          { value: "CG", label: "Congo" },
          { value: "CD", label: "Congo, the Democratic Republic of the" },
          { value: "CK", label: "Cook Islands" },
          { value: "CR", label: "Costa Rica" },
          { value: "CI", label: "Côte d'Ivoire" },
          { value: "HR", label: "Croatia" },
          { value: "CU", label: "Cuba" },
          { value: "CW", label: "Curaçao" },
          { value: "CY", label: "Cyprus" },
          { value: "CZ", label: "Czech Republic" },
          { value: "DK", label: "Denmark" },
          { value: "DJ", label: "Djibouti" },
          { value: "DM", label: "Dominica" },
          { value: "DO", label: "Dominican Republic" },
          { value: "EC", label: "Ecuador" },
          { value: "EG", label: "Egypt" },
          { value: "SV", label: "El Salvador" },
          { value: "GQ", label: "Equatorial Guinea" },
          { value: "ER", label: "Eritrea" },
          { value: "EE", label: "Estonia" },
          { value: "ET", label: "Ethiopia" },
          { value: "FK", label: "Falkland Islands (Malvinas)" },
          { value: "FO", label: "Faroe Islands" },
          { value: "FJ", label: "Fiji" },
          { value: "FI", label: "Finland" },
          { value: "FR", label: "France" },
          { value: "GF", label: "French Guiana" },
          { value: "PF", label: "French Polynesia" },
          { value: "TF", label: "French Southern Territories" },
          { value: "GA", label: "Gabon" },
          { value: "GM", label: "Gambia" },
          { value: "GE", label: "Georgia" },
          { value: "DE", label: "Germany" },
          { value: "GH", label: "Ghana" },
          { value: "GI", label: "Gibraltar" },
          { value: "GR", label: "Greece" },
          { value: "GL", label: "Greenland" },
          { value: "GD", label: "Grenada" },
          { value: "GP", label: "Guadeloupe" },
          { value: "GU", label: "Guam" },
          { value: "GT", label: "Guatemala" },
          { value: "GG", label: "Guernsey" },
          { value: "GN", label: "Guinea" },
          { value: "GW", label: "Guinea-Bissau" },
          { value: "GY", label: "Guyana" },
          { value: "HT", label: "Haiti" },
          { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
          { value: "VA", label: "Holy See (Vatican City State)" },
          { value: "HN", label: "Honduras" },
          { value: "HK", label: "Hong Kong" },
          { value: "HU", label: "Hungary" },
          { value: "IS", label: "Iceland" },
          { value: "IN", label: "India" },
          { value: "ID", label: "Indonesia" },
          { value: "IR", label: "Iran, Islamic Republic of" },
          { value: "IQ", label: "Iraq" },
          { value: "IE", label: "Ireland" },
          { value: "IM", label: "Isle of Man" },
          { value: "IL", label: "Israel" },
          { value: "IT", label: "Italy" },
          { value: "JM", label: "Jamaica" },
          { value: "JP", label: "Japan" },
          { value: "JE", label: "Jersey" },
          { value: "JO", label: "Jordan" },
          { value: "KZ", label: "Kazakhstan" },
          { value: "KE", label: "Kenya" },
          { value: "KI", label: "Kiribati" },
          { value: "KP", label: "Korea, Democratic People's Republic of" },
          { value: "KR", label: "Korea, Republic of" },
          { value: "KW", label: "Kuwait" },
          { value: "KG", label: "Kyrgyzstan" },
          { value: "LA", label: "Lao People's Democratic Republic" },
          { value: "LV", label: "Latvia" },
          { value: "LB", label: "Lebanon" },
          { value: "LS", label: "Lesotho" },
          { value: "LR", label: "Liberia" },
          { value: "LY", label: "Libya" },
          { value: "LI", label: "Liechtenstein" },
          { value: "LT", label: "Lithuania" },
          { value: "LU", label: "Luxembourg" },
          { value: "MO", label: "Macao" },
          { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
          { value: "MG", label: "Madagascar" },
          { value: "MW", label: "Malawi" },
          { value: "MY", label: "Malaysia" },
          { value: "MV", label: "Maldives" },
          { value: "ML", label: "Mali" },
          { value: "MT", label: "Malta" },
          { value: "MH", label: "Marshall Islands" },
          { value: "MQ", label: "Martinique" },
          { value: "MR", label: "Mauritania" },
          { value: "MU", label: "Mauritius" },
          { value: "YT", label: "Mayotte" },
          { value: "MX", label: "Mexico" },
          { value: "FM", label: "Micronesia, Federated States of" },
          { value: "MD", label: "Moldova, Republic of" },
          { value: "MC", label: "Monaco" },
          { value: "MN", label: "Mongolia" },
          { value: "ME", label: "Montenegro" },
          { value: "MS", label: "Montserrat" },
          { value: "MA", label: "Morocco" },
          { value: "MZ", label: "Mozambique" },
          { value: "MM", label: "Myanmar" },
          { value: "NA", label: "Namibia" },
          { value: "NR", label: "Nauru" },
          { value: "NP", label: "Nepal" },
          { value: "NL", label: "Netherlands" },
          { value: "NC", label: "New Caledonia" },
          { value: "NZ", label: "New Zealand" },
          { value: "NI", label: "Nicaragua" },
          { value: "NE", label: "Niger" },
          { value: "NG", label: "Nigeria" },
          { value: "NU", label: "Niue" },
          { value: "NF", label: "Norfolk Island" },
          { value: "MP", label: "Northern Mariana Islands" },
          { value: "NO", label: "Norway" },
          { value: "OM", label: "Oman" },
          { value: "PK", label: "Pakistan" },
          { value: "PW", label: "Palau" },
          { value: "PS", label: "Palestine, State of" },
          { value: "PA", label: "Panama" },
          { value: "PG", label: "Papua New Guinea" },
          { value: "PY", label: "Paraguay" },
          { value: "PE", label: "Peru" },
          { value: "PH", label: "Philippines" },
          { value: "PN", label: "Pitcairn" },
          { value: "PL", label: "Poland" },
          { value: "PT", label: "Portugal" },
          { value: "PR", label: "Puerto Rico" },
          { value: "QA", label: "Qatar" },
          { value: "RE", label: "Réunion" },
          { value: "RO", label: "Romania" },
          { value: "RU", label: "Russian Federation" },
          { value: "RW", label: "Rwanda" },
          { value: "BL", label: "Saint Barthélemy" },
          { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
          { value: "KN", label: "Saint Kitts and Nevis" },
          { value: "LC", label: "Saint Lucia" },
          { value: "MF", label: "Saint Martin (French part)" },
          { value: "PM", label: "Saint Pierre and Miquelon" },
          { value: "VC", label: "Saint Vincent and the Grenadines" },
          { value: "WS", label: "Samoa" },
          { value: "SM", label: "San Marino" },
          { value: "ST", label: "Sao Tome and Principe" },
          { value: "SA", label: "Saudi Arabia" },
          { value: "SN", label: "Senegal" },
          { value: "RS", label: "Serbia" },
          { value: "SC", label: "Seychelles" },
          { value: "SL", label: "Sierra Leone" },
          { value: "SG", label: "Singapore" },
          { value: "SX", label: "Sint Maarten (Dutch part)" },
          { value: "SK", label: "Slovakia" },
          { value: "SI", label: "Slovenia" },
          { value: "SB", label: "Solomon Islands" },
          { value: "SO", label: "Somalia" },
          { value: "ZA", label: "South Africa" },
          { value: "GS", label: "South Georgia and the South Sandwich Islands" },
          { value: "SS", label: "South Sudan" },
          { value: "ES", label: "Spain" },
          { value: "LK", label: "Sri Lanka" },
          { value: "SD", label: "Sudan" },
          { value: "SR", label: "Suriname" },
          { value: "SJ", label: "Svalbard and Jan Mayen" },
          { value: "SZ", label: "Swaziland" },
          { value: "SE", label: "Sweden" },
          { value: "CH", label: "Switzerland" },
          { value: "SY", label: "Syrian Arab Republic" },
          { value: "TW", label: "Taiwan, Province of China" },
          { value: "TJ", label: "Tajikistan" },
          { value: "TZ", label: "Tanzania, United Republic of" },
          { value: "TH", label: "Thailand" },
          { value: "TL", label: "Timor-Leste" },
          { value: "TG", label: "Togo" },
          { value: "TK", label: "Tokelau" },
          { value: "TO", label: "Tonga" },
          { value: "TT", label: "Trinidad and Tobago" },
          { value: "TN", label: "Tunisia" },
          { value: "TR", label: "Turkey" },
          { value: "TM", label: "Turkmenistan" },
          { value: "TC", label: "Turks and Caicos Islands" },
          { value: "TV", label: "Tuvalu" },
          { value: "UG", label: "Uganda" },
          { value: "UA", label: "Ukraine" },
          { value: "AE", label: "United Arab Emirates" },
          { value: "GB", label: "United Kingdom" },
          { value: "US", label: "United States" },
          { value: "UM", label: "United States Minor Outlying Islands" },
          { value: "UY", label: "Uruguay" },
          { value: "UZ", label: "Uzbekistan" },
          { value: "VU", label: "Vanuatu" },
          { value: "VE", label: "Venezuela, Bolivarian Republic of" },
          { value: "VN", label: "Viet Nam" },
          { value: "VG", label: "Virgin Islands, British" },
          { value: "VI", label: "Virgin Islands, U.S." },
          { value: "WF", label: "Wallis and Futuna" },
          { value: "EH", label: "Western Sahara" },
          { value: "YE", label: "Yemen" },
          { value: "ZM", label: "Zambia" },
          { value: "ZW", label: "Zimbabwe" },
        ],
        messageModal:false,
        headers:[
          { text: "ID", align: "left", sortable: true },
          { text: "Name", align: "left", sortable: true },
          { text: "Email", align: "left", sortable: false },
          { text: "Lang", align: "left", sortable: false },
        ]
      }
    },
    methods:{
      removeMessageImage(){
        this.message.image = null;
      },
      successMessageImage(file){
        this.message.image = file.xhr.response;
      },
      maxFileSexceededMessageImage(file){
        this.$refs.addMessageImage.removeAllFiles();
        this.$refs.addMessageImage.addFile(file);
      },
      async customEvent(_file, xhr, formData) {
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append(
          "hotel_id",
          this.$store.state.objects.item.id
        );
        formData.append("image", _file);
      },
      async changeHotel() {
        const status = await NotificationService.getHotelFireBaseStatus(this.$store.state.objects.item.id);
        this.status = !!status;
        this.users = await NotificationService.getFirebaseClients(this.$store.state.objects.item.id);
      },
      async sendMessage(){
        const payload = {
          ...this.message,
          hotel_id:this.$store.state.objects.item.id,
          client_ids:
            this.selectedUsers.map(item => {
              return item.id
            })
        };
        this.successMessage = await NotificationService.sendFirebaseNotification(payload);
        this.messageModal = false;
        this.$refs.PopupSuccess.open();
      },
      closeMessageModal(){
        this.messageModal = false;
      },
      showMessageModal(){
        if(this.$refs.addMessageImage){
          this.$refs.addMessageImage.removeAllFiles();
        }
        this.message = {
          image:null,
          title:"default title",
          message:"default body",
          url:''
        };
        this.messageModal = true;
      },
      changeFormat(lang) {
        const langArr = lang.split("-");
        if (langArr[1]) {
          const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
          );
          if (candidate) {
            return `${candidate.label} <span style="color:#ccc;">(${lang})</span>`;
          }
        } else {
          const candidate = this.countries.find(
            (x) => x.value === langArr[0].toUpperCase()
          );
          return `${
            candidate.label
          } <span style="color:#ccc;">(${lang}-${lang.toUpperCase()})</span>`;
        }
      },
      changeFormatLangPic(lang) {
        const langArr = lang.split("-");
        if (langArr[1]) {
          const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
          );
          if (candidate) {
            return candidate.value;
          }
        } else {
          return lang.toUpperCase();
        }
      },
      async toggleFirebaseStatus(){
        await NotificationService.toggleFirebaseStatus(this.$store.state.objects.item.id);
      },
    },
    async mounted() {
      if (this.$route.query.id) {
        const candidate = this.$store.state.objects.list.find(
          (x) => x.id === +this.$route.query.id
        );
        if (candidate) {
          this.$store.state.objects.item = candidate;
        } else {
          this.$store.state.objects.item = this.$store.state.objects.list[0];
        }
      } else {
        this.$store.state.objects.item = this.$store.state.objects.list[0];
      }
      const status = await NotificationService.getHotelFireBaseStatus(this.$store.state.objects.item.id);
      this.status = !!status;
      this.users = await NotificationService.getFirebaseClients(this.$store.state.objects.item.id);
    }
  }
</script>

<style lang="scss">

  .card_body{
    padding:15px;
    &_item{
      margin-bottom:15px;
      & label{
        margin-bottom:10px;
      }
    }
  }
  .dz-size {
    display: none;
  }
  .dz-filename {
    display: none;
  }
  .vue-dropzone > .dz-preview .dz-image {
    max-width: 150px;
    max-height: 150px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .tariff_obj {
    margin-left:5px;
    display: inline-block;
    padding: 4px 6px;
    text-transform: uppercase;
    border-radius: 0 5px 0 5px;
    background: #007db5;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    color: #fff;
  }
  .copiedTelegram{
    position: fixed;
    left:calc(50% - 185px);
    top:5px;
    z-index: 10;
    width:370px;
  }
</style>
